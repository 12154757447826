
const icons = {
    play: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
</svg>`,
    pause: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
</svg>`
}

class Timer extends HTMLElement {
    static observedAttributes = ["data-seconds", "data-estimated"];

    /**
     * @type {HTMLElement}
     */
    icon = null;
    /**
     * @type {HTMLElement}
     */
    timer = null;

    constructor() {
        super();
        this.timer = this.querySelector('[data-time]');
        this.icon = this.querySelector('[data-icon]');
    }

    connectedCallback() {
        this.setIcon('play')
        this.addEventListener('click', function(e) {
            if(this.icon.dataset.action === 'pause') {
                window.timers.requestStopCurrentTimer();
            }
            if(this.icon.dataset.action === 'play') {
                window.timers.requestStartTimerFor(this.dataset.task);
            }
        }.bind(this));
        this.updateTimeText(this.dataset.seconds ?? 0);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
        case 'data-seconds':
            this.updateTimeText(newValue)
            break;
        }
    }

    updateTimeText(seconds) {
        const estimated = parseInt(this.dataset.estimated ?? 0);

        this.timer.textContent = this.formatSecondsToTime(seconds);

        if(estimated > 0) {
            this.timer.textContent += ' / ' + this.formatSecondsToTime(estimated);
            if(seconds > estimated) {
                this.timer.style.color = '#cf1e1e';
            } else {
                this.timer.style.removeProperty('color');
            }
        }
    }

    formatSecondsToTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainderSeconds = seconds % 60;
        if(minutes < 60) {
            return this.formatTimestring(0, minutes, remainderSeconds);
        }
        const hours = Math.floor(minutes / 60);
        const remainderMinutes = minutes % 60;
        return this.formatTimestring(hours, remainderMinutes, remainderSeconds);
    }

    /**
     *
     * @param {number} hours
     * @param {number} minutes
     * @param {number} seconds
     * @returns {string}
     */
    formatTimestring(hours, minutes, seconds) {
        return [hours, minutes, seconds]
            .map(part => part.toString().padStart(2, '0'))
            .join(':');
    }

    setIcon(icon) {
        this.icon.dataset.action = icon;
        this.icon.innerHTML = icons[icon];
    }
}

customElements.define('task-timer', Timer);
