
const userId = window.userId;
const axios = window.axios;


// Convert a base64 string to Uint8Array.
// Must do this so the server can understand the VAPID_PUBLIC_KEY.
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const MyNotifications = {
    async subscribe() {
        if (!('serviceWorker' in navigator && 'PushManager' in window)) {
            console.error('Browser does not support service workers or push messages.');
            return;
        }

        if(Notification.permission === 'granted') {
            console.info('Notification permission already granted.');
            return;
        }

        if(Notification.permission === 'denied') {
            console.info('Notification permission denied.');
            return;
        }

        const response = await axios.get('/admin/user/push/status').then(r => r.data)
        if(!response.is_push_enabled) {
            console.log('The user forbid notifications through server.');
            return;
        }

        const result = await Notification.requestPermission();

        if (result === 'denied') {
            console.error('The user explicitly denied the permission request.');
            return;
        }

        if (result === 'granted') {
            console.info('The user accepted the permission request.');
            const registration = await navigator.serviceWorker.getRegistration();
            const subscribed = await registration.pushManager.getSubscription();
            if (subscribed) {
                console.info('User is already subscribed.');
                return;
            }

            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlB64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY)
            });

            return axios.post('/admin/user/push/subscribe', subscription.toJSON());
        }
    },
    unsubscribe() {},
    /**
     * @param {HTMLElement} button
     */
    subscriptionButton(button) {
        button.addEventListener('click', function() {
            MyNotifications.subscribe()
        })
    }
}
window.MyNotifications = MyNotifications

const button = document.querySelector('.fi-topbar-database-notifications-btn')
if(button) {
    MyNotifications.subscriptionButton(button)
}
