import './timer.js'

const broadcast = new BroadcastChannel('timers');

window.timers = {
    currentInterval: null,
    get timers() {
        return document.querySelectorAll(`task-timer[data-task="${window.currentTimer}"]`);
    },
    init() {
        this.windUpCurrentTimer()
    },
    incrementTimer(timer) {
        timer.setAttribute('data-seconds', parseInt(timer.dataset.seconds) + 1);
    },
    windUpCurrentTimer() {
        this.timers.forEach(timer => timer.setIcon('pause'))
        this.currentInterval = setInterval(() => {
            for(const timer of this.timers) {
                this.incrementTimer(timer)
            }
        }, 1000);
    },
    requestStopCurrentTimer() {
        axios.post('/admin/timer/stop')
            .then(r => r.data)
            .then(data => {
                this.stopInterval(data.seconds);
                broadcast.postMessage({
                    method: 'stopInterval',
                    arguments: [data.seconds]
                });
                //this.stopInterval();
            })
    },
    stopInterval(seconds) {
        clearInterval(this.currentInterval);
        this.currentInterval = null;
        this.timers.forEach(timer => {
            timer.setIcon('play');
            timer.setAttribute('data-seconds', seconds);
        });
        window.currentTimer = null;
    },
    requestStartTimerFor(task_id) {
        axios.post('/admin/timer/start', {
            task_id
        })
            .then(r => r.data)
            .then(timer => {
                this.windUpNewTaskAfterRequest(timer);
                broadcast.postMessage({
                    method: 'windUpNewTaskAfterRequest',
                    arguments: [timer]
                })
            })
    },
    windUpNewTaskAfterRequest(timer) {
        this.stopInterval(timer.seconds);
        window.currentTimer = timer.task_id;
        this.updateHeader(timer);
        this.windUpCurrentTimer();
    },
    requestFinish(task_id) {
        axios.post('/admin/timer/finish', {
            task_id
        })
            .then(r => r.data)
            .then(data => {
                this.afterFinish(data.timer, data.headerTimer)
                broadcast.postMessage({
                    method: 'afterFinish',
                    arguments: [data.timer, data.headerTimer]
                });
            })
    },
    afterFinish(timer, headerTimer) {
        if(parseInt(window.currentTimer) === parseInt(timer.task_id)) {
            this.stopInterval(timer.seconds);
        }
        document.querySelector(`#timer_${timer.task_id}`).remove();
        this.updateHeader(headerTimer);
    },
    updateHeader(timer) {
        /**
         * @type {HTMLElement}
         */
        const headerTimer = document.querySelector('#headerTimer');
        /**
         * @type {HTMLElement}
         */
        const headerTask = document.querySelector('#headerTask');
        if(timer) {
            headerTimer.setAttribute('data-task', timer.task_id);
            headerTimer.setAttribute('data-seconds', timer.seconds);
            headerTimer.style.display = '';

            headerTask.href = timer.task.links.preview;
            headerTask.textContent = `[#${timer.task_id}] ${timer.task.name}`;
            headerTask.style.display = '';
        } else {
            headerTimer.style.setProperty('display', 'none', 'important');
            headerTask.style.setProperty('display', 'none', 'important');
        }
    }
}

broadcast.onmessage = function(event) {
    console.log(event.data);
    if('method' in event.data) {
        const args = event.data.arguments ?? [];
        window.timers[event.data.method](...args);
    }
}

window.timers.init();
